var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", { staticClass: " " }, [
        _c(
          "div",
          { staticClass: "inner-container only-heading-part mt-0" },
          [
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content pt-0",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "div",
                    { attrs: { id: "app" } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center mb-8 mt-8" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToRules()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v("mdi-arrow-left"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "h5",
                              {
                                staticClass: "primarybase--text h5--text-xbold",
                              },
                              [_vm._v(" Strategic Losing ")]
                            ),
                          ],
                          1
                        ),
                        [
                          _c(
                            "v-form",
                            {
                              ref: "stLosingForm",
                              model: {
                                value: _vm.stLosingFormValid,
                                callback: function ($$v) {
                                  _vm.stLosingFormValid = $$v
                                },
                                expression: "stLosingFormValid",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    elevation: "0",
                                    variant: "outlined",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "inner-container only-heading-part",
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        {
                                          staticClass:
                                            "v-main-content only-simple-content",
                                          attrs: { fluid: "" },
                                        },
                                        [
                                          [
                                            _c(
                                              "div",
                                              { attrs: { id: "app" } },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center mt-4",
                                                    attrs: { elevation: "0" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {},
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "12",
                                                              md: "12",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                label:
                                                                  "Strategic Losing Name",
                                                                placeholder:
                                                                  "Name",
                                                                required: "",
                                                                rules: [
                                                                  function (v) {
                                                                    return (
                                                                      !!v ||
                                                                      "Name cannot be empty"
                                                                    )
                                                                  },
                                                                ],
                                                              },
                                                              model: {
                                                                value: _vm.name,
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.name = $$v
                                                                },
                                                                expression:
                                                                  "name",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "6",
                                                              md: "6",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              staticClass: " ",
                                                              attrs: {
                                                                placeholder:
                                                                  "Select an App",
                                                                label: "App",
                                                                items:
                                                                  _vm.appsList,
                                                                loading:
                                                                  _vm.loadingApps,
                                                                "item-text":
                                                                  "appName",
                                                                "item-value":
                                                                  "adamId",
                                                                required: "",
                                                                "prepend-inner-icon":
                                                                  "mdi-cellphone",
                                                                "return-object":
                                                                  "",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.changeApp,
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key: "item",
                                                                    fn: function (
                                                                      data
                                                                    ) {
                                                                      return [
                                                                        [
                                                                          _c(
                                                                            "v-img",
                                                                            {
                                                                              staticClass:
                                                                                "rounded-lg",
                                                                              attrs: {
                                                                                color:
                                                                                  "secondary",
                                                                                "lazy-src":
                                                                                  data
                                                                                    .item
                                                                                    .appIcon,
                                                                                "max-width":
                                                                                  "30",
                                                                                "max-height":
                                                                                  "30",
                                                                                "min-width":
                                                                                  "20",
                                                                                "min-height":
                                                                                  "20",
                                                                                src:
                                                                                  data
                                                                                    .item
                                                                                    .appIcon,
                                                                              },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            {
                                                                              staticClass:
                                                                                "ml-2",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        data
                                                                                          .item
                                                                                          .appName
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          data
                                                                            .item
                                                                            .attrExist
                                                                            ? _c(
                                                                                "v-label",
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      "secondary",
                                                                                    disabled: true,
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Attributed "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          !data
                                                                            .item
                                                                            .attrExist
                                                                            ? _c(
                                                                                "v-label",
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      "secondary",
                                                                                    disabled: true,
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Not Attributed "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                      ]
                                                                    },
                                                                  },
                                                                ]
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.selectedApp,
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.selectedApp = $$v
                                                                },
                                                                expression:
                                                                  "selectedApp",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "6",
                                                              md: "6",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-autocomplete",
                                                              {
                                                                staticClass:
                                                                  "  ",
                                                                attrs: {
                                                                  placeholder:
                                                                    "Select campaigns",
                                                                  label:
                                                                    "Campaigns",
                                                                  items:
                                                                    _vm.campsList,
                                                                  loading:
                                                                    _vm.loadingCamps,
                                                                  "item-text":
                                                                    "name",
                                                                  "item-value":
                                                                    "id",
                                                                  required: "",
                                                                  multiple: "",
                                                                  "validate-on-blur":
                                                                    "",
                                                                  rules: [
                                                                    function (
                                                                      v
                                                                    ) {
                                                                      return (
                                                                        (!!v &&
                                                                          v.length >
                                                                            0) ||
                                                                        "Please select atleast 1 campaign"
                                                                      )
                                                                    },
                                                                  ],
                                                                  "prepend-inner-icon":
                                                                    "mdi-campaign",
                                                                  "return-object":
                                                                    "",
                                                                  disabled:
                                                                    !_vm.appsList ||
                                                                    _vm.appsList
                                                                      .length ===
                                                                      0,
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.campaignsSelected,
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "item",
                                                                      fn: function (
                                                                        data
                                                                      ) {
                                                                        return [
                                                                          [
                                                                            _c(
                                                                              "v-list-item-content",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-2",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-title",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          data
                                                                                            .item
                                                                                            .name
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-label",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "secondary",
                                                                                  disabled: true,
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    data
                                                                                      .item
                                                                                      .status
                                                                                  ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm.selectedCamps,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedCamps = $$v
                                                                  },
                                                                  expression:
                                                                    "selectedCamps",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "6",
                                                              md: "6",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-autocomplete",
                                                              {
                                                                ref: "goalsRef",
                                                                staticClass:
                                                                  "  ",
                                                                attrs: {
                                                                  placeholder:
                                                                    "Select Goals",
                                                                  label:
                                                                    "Goals",
                                                                  items:
                                                                    _vm.goalList,
                                                                  loading:
                                                                    _vm.loadingGoals,
                                                                  "item-text":
                                                                    "name",
                                                                  "item-value":
                                                                    "_id",
                                                                  required: "",
                                                                  "prepend-inner-icon":
                                                                    "mdi-bullseye",
                                                                  "return-object":
                                                                    "",
                                                                  multiple: "",
                                                                  "validate-on-blur":
                                                                    "",
                                                                  rules:
                                                                    _vm.goalSelectionRules,
                                                                  disabled:
                                                                    !_vm.appsList ||
                                                                    _vm.appsList
                                                                      .length ===
                                                                      0,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.selectedGoals,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedGoals = $$v
                                                                  },
                                                                  expression:
                                                                    "selectedGoals",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "6",
                                                              md: "6",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 greybase--text caption--text d-block",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Bid Intensity "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-start w-100 mt-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "primary--text whitespace-nowrap mt-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Lose more"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-slider",
                                                                    {
                                                                      staticClass:
                                                                        "px-4 mt-1",
                                                                      attrs: {
                                                                        step: 0.1,
                                                                        "thumb-label": false,
                                                                        min:
                                                                          "-0.4",
                                                                        max:
                                                                          "+0.4",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.bidIntensity,
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.bidIntensity = $$v
                                                                        },
                                                                        expression:
                                                                          "bidIntensity",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "primary--text whitespace-nowrap mt-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Win more"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "6",
                                                              md: "6",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "Max bid",
                                                                placeholder:
                                                                  "max bid",
                                                                rules:
                                                                  _vm.maxBidRules,
                                                                required: "",
                                                                type: "number",
                                                                max: "30",
                                                                min: "1",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.maxbid,
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.maxbid = $$v
                                                                },
                                                                expression:
                                                                  "maxbid",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                      ],
                    ],
                    2
                  ),
                ],
                _c(
                  "div",
                  { staticClass: "w-100 d-flex justify-end mt-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "px-6",
                        attrs: { color: "primary" },
                        on: { click: _vm.createRules },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export const timezones = [
  {
    value: 'UTC-12:00',
    text: 'UTC-12:00 (e.g., Baker Island Time, Howland Island Time) ',
  },
  {
    value: 'UTC-11:00',
    text: 'UTC-11:00 (e.g., Samoa Standard Time, Niue Time) ',
  },
  {
    value: 'UTC-10:00',
    text: 'UTC-10:00 (e.g., Hawaii-Aleutian Standard Time, Cook Islands Time) ',
  },
  {
    value: 'UTC-09:30',
    text: 'UTC-09:30 (e.g., Marquesas Islands Time) ',
  },
  {
    value: 'UTC-09:00',
    text: 'UTC-09:00 (e.g., Alaska Standard Time, Gambier Islands Time) ',
  },
  {
    value: 'UTC-08:00',
    text: 'UTC-08:00 (e.g., Pacific Standard Time, Alaska Standard Time) ',
  },
  {
    value: 'UTC-07:00',
    text: 'UTC-07:00 (e.g., Mountain Standard Time, Pacific Standard Time) ',
  },
  {
    value: 'UTC-06:00',
    text: 'UTC-06:00 (e.g., Central Standard Time, Mexico Standard Time) ',
  },
  {
    value: 'UTC-05:00',
    text: 'UTC-05:00 (e.g., Eastern Standard Time, Colombia Time) ',
  },
  {
    value: 'UTC-04:00',
    text: 'UTC-04:00 (e.g., Atlantic Standard Time, Amazon Standard Time) ',
  },
  {
    value: 'UTC-03:30',
    text: 'UTC-03:30 (e.g., Newfoundland Standard Time) ',
  },
  {
    value: 'UTC-03:00',
    text: 'UTC-03:00 (e.g., Argentina Time, Greenland Standard Time) ',
  },
  {
    value: 'UTC-02:00',
    text: 'UTC-02:00 (e.g., Fernando de Noronha Time, South Georgia Time) ',
  },
  {
    value: 'UTC-01:00',
    text: 'UTC-01:00 (e.g., Azores Standard Time, Cape Verde Time) ',
  },
  { value: 'UTC+00:00', text: 'UTC+00:00 (Coordinated Universal Time) ' },
  {
    value: 'UTC+01:00',
    text: 'UTC+01:00 (e.g., Central European Time, West Africa Time) ',
  },
  {
    value: 'UTC+02:00',
    text: 'UTC+02:00 (e.g., Eastern European Time, Central Africa Time) ',
  },
  {
    value: 'UTC+03:00',
    text: 'UTC+03:00 (e.g., Moscow Time, Eastern Africa Time) ',
  },
  {
    value: 'UTC+04:00',
    text: 'UTC+04:00 (e.g., Gulf Standard Time, Samara Time) ',
  },
  {
    value: 'UTC+05:00',
    text: 'UTC+05:00 (e.g., Pakistan Standard Time, Yekaterinburg Time) ',
  },
  {
    value: 'UTC+05:30',
    text: 'UTC+05:30 (e.g., Indian Standard Time, Sri Lanka Time) ',
  },
  {
    value: 'UTC+06:00',
    text: 'UTC+06:00 (e.g., Bangladesh Standard Time, Bhutan Time) ',
  },
  {
    value: 'UTC+06:30',
    text: 'UTC+06:30 (e.g., Cocos Islands Time, Myanmar Time) ',
  },
  {
    value: 'UTC+07:00',
    text: 'UTC+07:00 (e.g., Indochina Time, Krasnoyarsk Time) ',
  },
  {
    value: 'UTC+08:00',
    text: 'UTC+08:00 (e.g., China Standard Time, Australian Western Standard Time) ',
  },
  {
    value: 'UTC+08:45',
    text: 'UTC+08:45 (e.g., Australian Central Western Standard Time) ',
  },
  {
    value: 'UTC+09:00',
    text: 'UTC+09:00 (e.g., Japan Standard Time, Korea Standard Time) ',
  },
  {
    value: 'UTC+09:30',
    text: 'UTC+09:30 (e.g., Australian Central Standard Time) ',
  },
  {
    value: 'UTC+10:00',
    text: 'UTC+10:00 (e.g., Australian Eastern Standard Time, Vladivostok Time) ',
  },
  {
    value: 'UTC+10:30',
    text: 'UTC+10:30 (e.g., Lord Howe Standard Time) ',
  },
  {
    value: 'UTC+11:00',
    text: 'UTC+11:00 (e.g., Magadan Time, Solomon Islands Time) ',
  },
  {
    value: 'UTC+12:00',
    text: 'UTC+12:00 (e.g., New Zealand Standard Time, Fiji Time) ',
  },
  {
    value: 'UTC+13:00',
    text: 'UTC+13:00 (e.g., Samoa Time, Tonga Time) ',
  },
  { value: 'UTC+14:00', text: 'UTC+14:00 (e.g., Line Islands Time) ' },
];
